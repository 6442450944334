<template>
  <div class="bg-gray-800 text-white">
    <!-- En-tête -->
    <HeaderVue />

    <div class="flex">
      <!-- Menu latéral -->
      <SidebarVue />
      <!-- Contenu principal -->
      <div class="w-full flex justify-center mt-16">
        <div class="p-4 w-full md:w-3/5">
          <ProductListVue />
          <PaginationVue />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderVue from "../HeaderVue.vue";
import SidebarVue from "../SidebarVue.vue";
import ProductListVue from "../ProductListVue.vue";
import PaginationVue from "./PaginationVue.vue";
// import axios from 'axios';

export default {
  components: {
    HeaderVue,
    SidebarVue,
    ProductListVue,
    PaginationVue,
  },

  methods: {
    // async login() {
    //   console.log("login")
    //   axios.get(process.env.VUE_APP_ROOT_API + '/')
    //     .then((res) => {
    //       console.log("res ===> " + res.data)
    //       res.data; // binary representation of the file
    //       res.status; // HTTP status
    //       this.loading = false
    //     }).catch((error) => {
    //       console.log("error")
    //       console.error('Error uploading image:', error);
    //   });
    // },
  },
};
</script>

<style>
/* Utilisez les classes Tailwind CSS pour styliser vos composants */
</style>
