<template>
  <div class="bg-gray-900 text-white flex items-center justify-between fixed w-full">
    <div class="flex items-center ml-4">
      <!-- <img src="../assets/logo-blanc-transparent.png" alt="SprintPro Logo" class="h-50 w-60 mr-2" /> -->
      <img
        src="../assets/logo-transparent.png"
        alt="SprintPro Logo"
        class="h-50 w-60 mr-2 mt-2"
      />
      <!-- <input
        type="text"
        placeholder="Rechercher des produits..."
        class="bg-gray-800 text-white rounded-md px-2 py-1 w-64"
      /> -->
    </div>
    <div class="flex items-center">
      <div class="flex items-center mt-2 hidden md:block relative">
        <input
          type="text"
          placeholder="Rechercher des produits..."
          class="bg-gray-800 text-white rounded-md px-2 py-1 w-80 h-10 pl-11"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 absolute top-2 left-3 opacity-50"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </div>
      <div class="border border-gray-500 h-6 mt-2 mx-2 ml-4"></div>
      <div class="flex items-center space-x-4 mr-6">
        <!-- <button class="text-gray-400 hover:text-white">
        <i class="fas fa-bell"></i>
      </button>
      <button class="text-white-400 hover:text-white">
        <i class="fas fa-cog"></i>
      </button>
      <button class="text-gray-400 hover:text-white">
        <i class="fas fa-question-circle"></i>
      </button> -->
        <div class="flex items-center space-x-2 mt-2">
          <img
            src="../assets/pp.png"
            alt="User Profile"
            class="h-12 w-12 m-2 rounded-xl"
          />
          <!--  <span class="text-sm">{{username}}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      userItem: null,
    };
  },

  setup() {},

  mounted() {
    this.userItem = localStorage.getItem("user");
    if (this.userItem) {
      console.log("user on header exist");
      console.log("username on header ====> " + JSON.parse(this.userItem).username);
      if (JSON.parse(this.userItem).username) {
        this.username = JSON.parse(this.userItem).username;
      } else console.error("No username found");
    } else {
      console.error("error user");
    }
  },

  methods: {
    filteredList() {
      // return fruits.filter((fruit) =>
      //   fruit.toLowerCase().includes(input.value.toLowerCase())
      // );
    },
  },
};
</script>

<style scoped>
/* Styles for Header component */
</style>
