<template>
  <nav
    v-if="showBar"
    class="bg-gray-900 text-white h-screen flex fixed mt-12 bg-opacity-75"
  >
    <!-- Sidebar content -->
    <ul class="space-y-2">
      <!-- <button class="">
        <i class="fas fa-cog"></i>
      </button> -->

      <div class="flex justify-end w-full">
        <button @click="showBar = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <button class="flex">
        <div class="text-white py-1 w-12 h-12 rounded flex items-center text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
            />
          </svg>
        </div>
        <div class="mt-3">Dashboard</div>
      </button>

      <!-- <li class="p-4 hover:bg-gray-700"><i class="fas fa-home mr-2"></i> Accueil</li> -->

      <!-- <li class="p-4 hover:bg-gray-700"><i class="fas fa-home mr-2"></i> Accueil</li> -->

      <!-- Add other menu items here -->
      <button class="flex absolute bottom-16" @click="logout()">
        <div
          class="text-white w-12 h-12 rounded flex items-center text-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
            />
          </svg>
        </div>
        <div class="mt-3">Logout</div>
      </button>
      <!-- Dialogue -->
      <dialog ref="myDialog">
        <p>Merci d'avoir utilisé notre service !</p>
      </dialog>
    </ul>
  </nav>
  <button
    v-else
    class="text-white py-1 w-12 h-12 rounded flex items-center text-sm fixed mt-20 ml-5"
    @click="showBar = true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-10 h-10"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  </button>
  <div
    v-if="loading_logout"
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 duration-3000"
  >
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <div class="flex flex-col items-center">
        <div class="border-t-4 border-blue-500 w-16 h-16 rounded-full animate-spin"></div>
        <p class="text-gray-600 mt-2">{{ spinner_text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  // Component logic here
  data() {
    return {
      loading_logout: false,
      spinner_text: "",
      showBar: true,
    };
  },
  methods: {
    async logout() {
      this.spinner_text = "Deconnexion en cours..";
      this.loading_logout = true;
      await this.wait(1000)
      if (localStorage.getItem("access_token")) {
        console.log("storage item ==> " + localStorage.getItem("access_token"));
        localStorage.removeItem("access_token");
        this.loading_logout = false;
        router.push("/login");
        // alert("L'utilisateur a bien été déconnecté")
      } else {
        console.log("storage item not found");
      }
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    showDialog() {
      const dialog = this.$refs.myDialog;
      dialog.showModal();

      // Fermer automatiquement le dialogue après 3 secondes
      setTimeout(() => {
        dialog.close();
      }, 3000);
    }
  },
  mounted() {
    if (window.innerWidth < window.innerHeight) {
      this.showBar = false;
    }
  },
};
</script>

<style scoped>
/* Styles for Sidebar component */
.sidebar {
  top: 0px;
}
</style>
