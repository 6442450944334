<template>
  <div class="bg-gray-900 p-6 justify-center rounded-b-xl">
    <div class="border-b border-gray-700 border-1 my-4 w-full"></div>

    <div class="flex w-full justify-between">
      <div class="p-3">Page 1 sur 30</div>
      <!-- Add other page buttons here -->
      <div class="flex">
        <button
          class="text-gray-400 hover:text-white border p-3 rounded-xl md:w-36 flex justify-around"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>

          <div class="hidden md:block">Précédent</div>
        </button>

        <button
          class="text-gray-400 hover:text-white border p-3 rounded-xl md:w-36 ml-4 flex justify-around"
        >
          <div class="hidden md:block">Suivant</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component logic here
};
</script>

<style scoped>
/* Styles for Pagination component */
</style>
